import * as types from 'const/action.types'
import messages_es from '../locale-data/es.json'
import messages_en from '../locale-data/en.json'
import messages_it from '../locale-data/it.json'
import messages_pt from '../locale-data/pt.json'
import messages_ms from '../locale-data/ms.json'
import messages_id from '../locale-data/id.json'
import messages_tl from '../locale-data/tl.json'

const languages = {
    es: messages_es,
    en: messages_en,
    it: messages_it,
    pt: messages_pt,
    ms: messages_ms,
    id: messages_id,
    tl: messages_tl,
}

var language = navigator.language.split(/[-_]/)[0]
let customLanguage = sessionStorage.getItem('iflares_language')
if (customLanguage) {
    language = customLanguage
}
//sessionStorage.setItem('iflares_language', language);
var words = languages[language]
if (words === null) {
    language = 'en'
    words = languages['en']
}

const initialState = {
    lang: language,
    messages: words,
}

export function locales(state = initialState, action) {
    switch (action.type) {
        case types.SET_LANG:
            sessionStorage.setItem('iflares_language', action.lang)
            return {
                lang: action.lang,
                messages: languages[action.lang],
            }
        default:
            return state
    }
}

export function dictionary(state = {}, action) {
    switch (action.type) {
        case types.SET_DICTIONARY:
            return action.data
        default:
            return state
    }
}
